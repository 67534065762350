import React from 'react'

export default () => (
  <section id="awards-honors">
    <h2>Awards &amp; Honors</h2>
    <h3>OOSA Award</h3>
    <p>
      <em>USAA &mdash; February, 2018</em>
      <br />
      Awarded quarterly to an employee who promotes an innovative culture.
    </p>
    <h3>ABCD Award</h3>
    <p>
      <em>Placemark Investements &mdash; December, 2013</em>
      <br />
      Awarded annually to an employee that went Above and Beyond the Call of Duty.
    </p>
  </section>
);
